.whyGetresponse {
    font-family: var(--rubik);
    margin-top: 80px;
    margin-bottom: 32px;
    u {
        text-decoration-color: #00baff;
        text-decoration-thickness: 0.15em;
    }
}
.header {
    margin-top: 0;
    font-size: 34px;
    font-weight: 600;
    text-align: center;
    @media screen and (min-width: 980px) {
        text-align: left;
    }
    span {
        color: #37a2ff;
    }
}
.numbers {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    color: #000;
    margin-top: 39.5px;
    margin-bottom: 18px;
    @media screen and (min-width: 1440px) {
        transform: translate(-3px, 0);
    }
    > * {
        flex-grow: 1;
        min-width: 160px;
        max-width: 269px;
        text-align: center;
        @media screen and (min-width: 980px) {
            text-align: initial;
            flex-basis: 0;
        }
    }
    em {
        display: inline-block;
        --underline-offset: 0.17em;
        --underline-offset-left: 0;
        --underline-offset-right: 0.2em;
    }
    li > em:first-of-type {
        font-size: 51px;
        font-weight: 700;
        font-style: normal;
        color: #000;
        span {
            white-space: nowrap;
        }

        small {
            display: inline-block;
            font-size: 100%;
            vertical-align: middle;
        }
    }
    li > p,
    li > div {
        font-size: 17px;
        line-height: 1.5;
        margin: 1.3em 0 1em;
        @media screen and (min-width: 980px) {
            max-width: 80%;
        }
    }
}
.testimonials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    position: relative;
    @media screen and (min-width: 980px) {
        justify-content: space-between;
    }
    > * {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 200px;
    }
    img {
        filter: grayscale(1);
        max-height: 50px;
    }
}
