.goals {
    text-align: center;
    max-width: 1181px;
    margin: 48px auto 0;
    u {
        text-decoration-color: #00baff;
        text-decoration-thickness: 0.15em;
    }
}
.preheader {
    font-family: var(--rubik);
    font-size: 14px;
    font-weight: 700;
    line-height: 1.42;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #717171;
}
.header {
    --private-padding-top: 1.5em;
    font-size: clamp(28px, 5vw, 34px);
    font-weight: 600;
    margin-bottom: 0.75em;
    span {
        color: var(--primary-brand-color-600);
    }
    @media screen and (min-width: 768px) {
        text-wrap: balance;
        max-width: 1140px;
        line-height: 1.25;
        margin: auto;
        letter-spacing: 0.04em;
    }
    @media screen and (min-width: 1440px) {
        font-size: 34px;
    }
}

.tabContent {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    @media screen and (min-width: 580px) {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    @media screen and (min-width: 768px) {
        opacity: 0.2;
        transition: opacity 0.4s ease;
    }
    @media screen and (min-width: 1440px) {
        grid-template-columns: 600px auto;
    }
}
.tabContentActive {
    @media screen and (min-width: 768px) {
        opacity: 1;
    }
}
.tabContentAnimation {
    min-width: 280px;
    max-width: 100%;
    box-sizing: border-box;
    @media screen and (min-width: 580px) {
        grid-column: 1 / span 1;
    }
    @media screen and (min-width: 1440px) {
        transform: translate(0, 0);
    }
    > div {
        @media screen and (min-width: 1440px) {
            transform: translate(16px, 33px);
        }
    }
    > * {
        max-width: 600px;
        margin: auto;
    }
    &[data-animation='2'] {
        @media screen and (min-width: 1440px) {
            transform: translate(2px, -12px);
        }
    }
    &[data-animation='3'] {
        @media screen and (min-width: 1440px) {
            transform: translate(2px, 2px);
        }
    }
    &[data-animation='4'] {
        @media screen and (min-width: 1440px) {
            transform: translate(2px, 6px);
        }
    }
}
.tabContentText {
    min-width: 200px;
    box-sizing: border-box;
    @media screen and (min-width: 580px) {
        max-width: 270px;
        grid-column: 2 / span 1;
        padding-right: 30px;
    }
    @media screen and (min-width: 1440px) {
        transform: translate(-6px, -26px);
        padding-right: 0;
    }

    ul {
        margin: 40px 0 0 20px;
        padding: 0;
        color: #4d5c70;
        list-style: none;
        li {
            padding-left: 20px;
            margin-top: 2.5em;
            font-size: 16px;
            line-height: 1.5;
            font-weight: 400;
            color: #000;
            &::before {
                content: '';
                position: absolute;
                width: 32px;
                height: 32px;
                background-size: 32px 32px;
                margin-left: -40px;
                margin-top: -2px;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        em {
            color: var(--primary-brand-color-600);
            display: block;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0.2em;
            font-size: 17px;
        }
    }
    &[data-tab='1'] {
        li {
            &:nth-of-type(1) {
                &::before {
                    background-image: url('./assets/new/collect_contacts_icon.svg');
                }
            }
            &:nth-of-type(2) {
                &::before {
                    background-image: url('./assets/new/nurture_subscribers_icon.svg');
                }
            }
            &:nth-of-type(3) {
                &::before {
                    background-image: url('./assets/new/optimize_with_analytics_icon.svg');
                }
            }
        }
    }
    &[data-tab='2'] {
        li {
            &:nth-of-type(1) {
                &::before {
                    background-image: url('./assets/new/convert_website_visitors_icon.svg');
                }
            }
            &:nth-of-type(2) {
                &::before {
                    background-image: url('./assets/new/nurture_subscribers_1_icon.svg');
                }
            }
            &:nth-of-type(3) {
                &::before {
                    background-image: url('./assets/new/retain_customers_icon.svg');
                }
            }
        }
    }
    &[data-tab='3'] {
        li {
            &:nth-of-type(1) {
                &::before {
                    background-image: url('./assets/new/synchronize_and_integrate_icon.svg');
                }
            }
            &:nth-of-type(2) {
                &::before {
                    background-image: url('./assets/new/promote_your_products_icon.svg');
                }
            }
            &:nth-of-type(3) {
                &::before {
                    background-image: url('./assets/new/increase_sales_conversions_icon.svg');
                }
            }
        }
    }
    &[data-tab='4'] {
        li {
            &:nth-of-type(1) {
                &::before {
                    background-image: url('./assets/new/effortless_migration_icon.svg');
                }
            }
            &:nth-of-type(2) {
                &::before {
                    background-image: url('./assets/new/engage_across_channels_icon.svg');
                }
            }
            &:nth-of-type(3) {
                &::before {
                    background-image: url('./assets/new/drive_more_sales_icon.svg');
                }
            }
        }
    }
}
