div:has(> .coreOfferBanner) {
    display: flex;
    flex-direction: column;
}
.coreOfferBanner {
    box-sizing: border-box;
    order: -1;
    padding: 17px;
    background-color: var(--primary-brand-color-600);
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    p {
        flex-basis: 980px;
        color: #fff;
        text-align: center;
        margin: 0;
        font-family: var(--rubik);
        font-size: 18px;
        font-weight: 500;
        line-height: 1.4;
    }
}
.button {
    color: #fff;
    border-color: #fff;
    &.arrow::after {
        transition: all 0.3s ease-in-out, border-color 0.3s ease-in-out;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }
}
