.g2reviews {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: #717171;
    margin-top: 40px;
    margin-bottom: -40px;
    letter-spacing: 0.04em;
}
.reverse {
    flex-direction: column-reverse;
}
