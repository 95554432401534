.banner {
    margin: 40px 0;
    padding: 6px 20px 16px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
    background-color: #000;
    font-size: 18px;
    box-sizing: border-box;
    z-index: 10;
    color: #fff;

    &.sticky {
        position: sticky;
        bottom: 30px;
    }
    @media screen and (min-width: 768px) {
        border-radius: 30px;
        gap: 20px 21px;
        padding: 13px 20px;
    }
    u {
        text-decoration-color: #fff;
        text-decoration-thickness: 0.15em;
    }

    p a {
        color: var(--primary-brand-color-600);
        text-decoration: none;
        font-weight: 700;
        font-size: 23.25px;
        transform: translateY(1.2px);
        display: inline-block;
        &:hover {
            text-decoration: underline;
        }
    }
}

.header {
    margin-top: 0;
}
.price {
    display: inline-block;
    margin: 0 0.3em 0 0.1em;
    line-height: 36px;
    transform: translateY(-2px);
}
.priceCurrency {
    font-size: 29px;
    font-weight: 700;
}
.priceValue {
    font-size: 29px;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
}
.pricePeriod {
    font-size: 29px;
    font-weight: 700;
}
