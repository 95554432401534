.testimonial {
    background: #fafafa;
    text-align: left;
    border-radius: 12px;
    padding: 34px 28px 34px 34px;
    display: flex;
    flex-direction: column;
    max-width: 350px;
    box-shadow: 10px 10px 40px -20px rgba(0, 0, 0, 0.2);
    position: relative;
    text-decoration: none;
    color: #000;
    transition: all 0.3s ease-in-out;
    border: 4px solid transparent;
    & > * {
        text-decoration: none;
    }
    &:hover {
        border: 4px solid #38a2ff;
    }
}
.logo {
    max-width: 130px;
    margin-bottom: 27px;
    img {
        width: auto;
        max-width: 100%;
        max-height: 30px;
    }
}
.header {
    font-size: 27px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: -0.03em;
    text-decoration: none;
    color: #000;
    line-height: 1.25;
    span {
        color: var(--primary-brand-color-600);
    }
}
.quote {
    padding: 20px 0 37px;
    margin: 0;
    line-height: 1.5;
    position: relative;
    font-size: 16px;
}
.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: auto;

    > * {
        flex-grow: 0;
        flex-shrink: 1;
    }
}
.button {
    flex-grow: 0;
    font-size: 14px;
}
