.main {
    background-color: #f5f7fa;
    background-image: url('../../../public/pages/homepage/bg3.svg'), url('../../../public/pages/homepage/bg1.svg'), url('../../../public/pages/homepage/bg2.svg');
    background-repeat: no-repeat;
    background-position: calc(50% - 418px) -30px, calc(50% - 357px) -370px, calc(50% + 570px) -170px;
    background-size: 1742px 1742px, 1210px 1210px, 1665px 1665px;
    background-origin: padding-box, padding-box;
    padding-bottom: 60px;
    font-family: var(--rubik);
    margin-top: calc(-1 * var(--header-height));
    border-top: var(--header-height) solid transparent;
}
.main + footer {
    background-color: #f5f7fa;
}
.main + footer::before {
    display: none;
}
.brands {
    margin-top: 40px;
}

:where(.container) {
    margin: auto;
    padding: 0 20px;
}

:where(.containerWrap) {
    margin: auto;
    padding: 0;
    max-width: 1180px;
    box-sizing: border-box;
    @media screen and (min-width: 768px) {
        padding: 0 20px;
    }
}

.g2reviews {
    margin-bottom: 90px;
}
