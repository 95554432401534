.wrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
    @media screen and (min-width: 768px) {
        flex-direction: row;
        align-items: center;
    }
}
.tabs {
    display: none;
    @media screen and (min-width: 768px) {
        align-self: flex-start;
        margin-top: 290px;
        display: block;
        z-index: 1;
        flex-direction: column;
        position: sticky;
        top: 50vh;
        transform: translateY(-50%);
        gap: 60px;
    }
}
.tab {
    font-size: 17px;
    font-weight: 700;
    text-decoration: none;
    color: #000;
    padding: 20px 17px 20px 27px;
    border-radius: 10px;
    text-wrap: balance;
    width: 229px;
    display: block;
    align-content: center;
    flex-wrap: wrap;
    transition: all 0.5s;
    background-color: rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    margin-bottom: 21px;
    &:hover {
        background-color: rgba(255, 255, 255, 1);
        color: var(--primary-brand-color-600);
    }
}
.activeTab {
    background-color: rgba(255, 255, 255, 1);
    color: var(--primary-brand-color-600);
    box-shadow: 0 2px 18px rgba(0, 0, 0, 0.1);
    &:hover {
        background-color: #fff;
        color: var(--primary-brand-color-600);
        box-shadow: 0 2px 18px rgba(0, 0, 0, 0.1);
    }
}
.content {
    @media screen and (min-width: 768px) {
        flex-grow: 1;
        display: grid;
        grid-template-rows: repeat(4, max(50vh, 600px));
        align-items: stretch;
        margin-bottom: max(25vh, 300px);
    }
    @media screen and (min-width: 1440px) {
        transform: translate(49px, 0);
    }
}
.mobileHeader {
    text-align: left;
    padding-left: 15px;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 1em;
    @media screen and (min-width: 768px) {
        display: none;
    }
}
.tabContentWrapper {
    display: flex;
    align-items: center;
}
.tabContent {
    z-index: 1;
    background-color: #f5f7fa;
    padding: 0 20px;
    box-sizing: border-box;
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        position: sticky;
        top: 50vh;
        height: 0;
        opacity: 0;
        transition: opacity 0.3s linear;
        padding: 0;
    }
}
.tabContentActive {
    opacity: 1;
}
.tabContent:nth-child(2) {
    z-index: 2;
}
.tabContent:nth-child(3) {
    z-index: 3;
}
.tabContent:nth-child(4) {
    z-index: 4;
}
