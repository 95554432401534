.integrations {
    margin-top: 40px;
}

.header {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.3;
    letter-spacing: 0.4px;
    span {
        color: var(--primary-brand-color-600);
    }
}
.header,
.header + p {
    max-width: 547px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 1024px) {
        margin-left: initial;
        margin-right: initial;
    }
}
.header + p {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 0.7em;
    margin-bottom: 2.4em;
}
.columns {
    @media screen and (min-width: 980px) {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto;
        align-items: center;
    }
}
.col-1 {
    box-sizing: border-box;
    text-align: center;
    @media screen and (min-width: 980px) {
        text-align: left;
        grid-column: 1 / span 1;
        grid-row: 1;
    }
    @media screen and (min-width: 1308px) {
        grid-column: 1 / span 2;
        grid-row: 1;
        padding-left: 14px;
        transform: translateY(-15px);
        max-width: 480px;
    }
}
.col-2 {
    max-width: 927px;
    grid-column: 2;
    grid-row: 1;
    figure {
        margin: 0;
        padding: 0;
        @media screen and (min-width: 1630px) {
            transform: translate(17px, 11px);
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}
