.wrapp {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.banner {
    margin: 40px 0 0;
    padding: 14px 20px 11px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: baseline;
    position: relative;
    box-sizing: border-box;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        background: #fbe30c;
        z-index: 0;
        @media screen and (min-width: 768px) {
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
        }
        @media screen and (min-width: 980px) {
            border-radius: 30px;
        }
    }

    p {
        color: #191b19;
        text-decoration: none;
        font-size: 17px;
        font-weight: 400;
        z-index: 0;
        flex-wrap: wrap;
        align-items: center;
        @media screen and (min-width: 980px) {
            display: flex;
            gap: 7px;
            margin-left: -8.3px;
        }
        & > span {
            font-size: 24px;
            font-weight: 700;
        }
    }
    a {
        z-index: 0;
        transform: translateX(9px);
    }
}
.header {
    margin-top: 0;
}
.footnote {
    margin: 0 auto;
    background: #161a21;
    padding: 20px 47px;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-sizing: border-box;
    @media screen and (max-width: 979px) {
        width: 100%;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        padding-left: 4px;
        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
    }
    img {
        margin: 0 auto 20px;
        @media screen and (min-width: 768px) {
            margin: 0;
            margin-right: 18px;
        }
    }
    p {
        color: #fff;
        font-size: 16.5px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1.9px;
        padding: 0;
        margin: 0;
        span {
            color: #fbe30c;
        }
    }
}
