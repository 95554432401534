.gradient {
    padding: 100px 0;
    margin: 0 0 -100px;
    background-image: url('./assets/bg_blue.svg'), url('./assets/bg_green.svg'), url('./assets/bg_yellow.svg');
    background-repeat: no-repeat;
    background-position: calc(50% - 540px) 10px, calc(50% - 120px) 100%, calc(50% + 390px) 80px;
    background-size: 471px 471px, 432px 432px, 508px 508px;
    background-origin: padding-box;
}
.courses {
    max-width: 100%;
    padding: 40px 20px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 2px solid #fff;
    text-align: center;
    position: relative;
    @media screen and (min-width: 980px) {
        padding: 60px 40px 70px;
    }
}
.preheader {
    color: #717171;
    text-align: center;
    font-family: var(--rubik);
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    margin: 0 0 8px;
}
.header {
    margin-bottom: 26px;
}
.title {
    color: #000;
    text-align: center;
    font-family: var(--rubik);
    font-size: 34px;
    font-weight: 600;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    --underline-offset: 0.26em;
    --underline-size: 0.14em;
    u,
    span {
        color: var(--primary-brand-color-600);
    }
    u {
        --private-padding-left: 0;
    }
}
.text {
    margin-bottom: 14px;
    text-align: left;
    p {
        color: #000;
        font-family: var(--rubik);
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        margin: 0 0 10px;
    }
}
.label {
    color: #fff;
    font-family: var(--rubik);
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    background: #39d464;
    display: inline-block;
    border-radius: 17px;
    position: absolute;
    left: 50%;
    top: -2px;
    padding: 8px 12px;
    box-sizing: border-box;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
}
.list {
    margin-bottom: 26px;
    display: flex;
    ul {
        text-align: left;
        padding: 0;
        list-style-type: none;
        li {
            margin-bottom: 17px;
            color: #000;
            font-family: var(--rubik);
            font-size: 16px;
            font-weight: 500;
            position: relative;
            padding-left: 22px;
            line-height: 1.2;
            &::before {
                content: '';
                position: absolute;
                width: 16px;
                height: 16px;
                background-image: url('./assets/bullet.svg');
                top: 1.5px;
                left: 0;
            }
        }
    }
}
.button {
    margin-top: 40px;
}
.blackFriday24Badge {
    color: #fff;
    height: 34px;
    min-width: 160px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: -2px;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    background-color: #000;
    background-image: url('./assets/blackFriday24/creator_badge.avif');
    background-size: auto 100%;
    text-indent: 14px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        transform: translateX(-25%);
        width: 86px;
        height: 86px;
        background-image: url('./assets/blackFriday24/creator_small.png');
        background-size: contain;
    }
}
